import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Button, TextField, Typography, Snackbar } from "@mui/material";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/login`, // Use the environment variable for API base URL
        {
          username,
          password,
        }
      );
      const tokenExpiry = Date.now() + 30 * 60 * 1000; // 30 minutes from now
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("authTokenExpiry", tokenExpiry);
      navigate("/admin"); // Redirect to admin page after successful login
    } catch (error) {
      setSnackbarMessage("Invalid credentials");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ width: "300px", margin: "auto", mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Admin Login
      </Typography>
      <form onSubmit={handleLogin}>
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default AdminLogin;
