import React from "react";
import { Container, Typography, Box } from "@mui/material";

const LegalDisclaimer = () => {
  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          Legal Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          MyZurich (“Company”) operates the website located at www.myzurich.com.
          This website is provided as a service to our visitors. By using this
          website, you are agreeing to the following terms and conditions.
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Ownership
        </Typography>
        <Typography variant="body1" paragraph>
          All content, trademarks, and logos presented on this website are the
          property of MyZurich unless otherwise noted. You may not reproduce,
          distribute, or use the content without prior written permission from
          MyZurich.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Content Accuracy
        </Typography>
        <Typography variant="body1" paragraph>
          While we strive to provide accurate and up-to-date information,
          MyZurich does not warrant the completeness or accuracy of the content.
          We are not responsible for any errors or omissions.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. External Links
        </Typography>
        <Typography variant="body1" paragraph>
          Our website may contain links to external sites that are not operated
          by us. We have no control over the content and practices of these
          sites and accept no responsibility for them.
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          MyZurich is not liable for any direct or indirect damages that may
          result from the use or inability to use the website or the content
          provided.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          For any legal inquiries, you can contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          MyZurich
          <br />
          123 Zurich St., 8001 Zurich, Switzerland
          <br />
          Email: info@myzurich.com
          <br />
          Phone: +41 123 456 7890
        </Typography>
      </Box>
    </Container>
  );
};

export default LegalDisclaimer;
