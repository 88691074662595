// src/pages/BookingDetails.js
import React from "react";
import { useLocation } from "react-router-dom";

const BookingDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // Retrieve booking details from the query parameters
  const place = query.get("place");
  const date = query.get("date");
  const time = query.get("time");
  const seats = query.get("seats");

  return (
    <div style={{ padding: "20px" }}>
      <h2>Booking Confirmation</h2>
      {place && date && time && seats ? (
        <div>
          <p>
            <strong>Place:</strong> {place}
          </p>
          <p>
            <strong>Date:</strong> {date}
          </p>
          <p>
            <strong>Time:</strong> {time}
          </p>
          <p>
            <strong>Seats:</strong> {seats}
          </p>
        </div>
      ) : (
        <p>No booking details found. Please make sure the URL is correct.</p>
      )}
    </div>
  );
};

export default BookingDetails;
