import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          General Terms and Conditions of Business (GTC)
        </Typography>
        <Typography variant="body1" paragraph>
          These General Terms and Conditions of Business (hereinafter referred
          to as “GTC”) govern the relationship between MyZurich (“Company”,
          “we”, “us”) and customers (“Organisers”). By using our services, you
          accept these terms.
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Scope
        </Typography>
        <Typography variant="body1" paragraph>
          These terms apply to all bookings, contracts, and services provided by
          MyZurich. Amendments to these terms require a written agreement.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Payment and Prices
        </Typography>
        <Typography variant="body1" paragraph>
          All prices are in Swiss francs (CHF) and include VAT. Full payment
          must be made by departure. For bookings, an advance deposit may be
          required.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. Cancellations
        </Typography>
        <Typography variant="body1" paragraph>
          Cancellation of bookings must be made in writing. Cancellations made
          within less than 24 hours of the event will be charged at 50% of the
          total cost.
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. Liability
        </Typography>
        <Typography variant="body1" paragraph>
          MyZurich is not liable for any personal injuries or property damage
          unless it is the result of gross negligence or intentional misconduct.
        </Typography>

        {/* Add more details as needed */}
        <Typography variant="h5" gutterBottom>
          5. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          MyZurich
          <br />
          Zurich, Switzerland
          <br />
          Email: info@myzurich.com
          <br />
          Phone: +41 123 456 7890
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
