import React from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We at MyZurich (“Company”, “we”, “us”, or “our”) take your privacy
          seriously and are committed to protecting your personal data. This
          privacy policy explains how we collect, use, and disclose information
          about you when you visit and interact with our website.
        </Typography>

        {/* Added clarification on personal data based on GDPR */}
        <Typography variant="h5" gutterBottom>
          1. What is this Privacy Policy about?
        </Typography>
        <Typography variant="body1" paragraph>
          This privacy policy informs you of how we collect, use, and store your
          personal data when you use our website www.myzurich.com and other
          affiliated sites. We collect personal data in accordance with Swiss
          Federal Act on Data Protection (FADP), New Federal Act on Data
          Protection (nFADP), and the European Union’s General Data Protection
          Regulation (GDPR).
        </Typography>
        <Typography variant="h5" gutterBottom>
          2. Who is responsible for processing your data?
        </Typography>
        <Typography variant="body1" paragraph>
          The data controller responsible for processing your data is MyZurich,
          based in Zurich, Switzerland. If you have any questions, you may
          contact us via email at info@myzurich.com.
        </Typography>

        {/* Add more details on the data collection */}
        <Typography variant="h5" gutterBottom>
          3. What data do we collect?
        </Typography>
        <Typography variant="body1" paragraph>
          We collect the following categories of personal data:
          <ul>
            <li>
              <b>Master data:</b> Basic information such as your name, email,
              and contact details.
            </li>
            <li>
              <b>Technical data:</b> IP addresses, device information, and
              browsing data.
            </li>
            <li>
              <b>Contractual data:</b> Information related to bookings,
              purchases, and other transactions.
            </li>
            <li>
              <b>Communication data:</b> Data collected via contact forms,
              emails, or any other communication channels.
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. What are your rights?
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, update, or request deletion of your
          personal data. If you believe that your personal data has been misused
          or mishandled, you have the right to lodge a complaint with the
          relevant supervisory authority.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Security of your data
        </Typography>
        <Typography variant="body1" paragraph>
          We take security measures to ensure your personal data is stored
          safely and securely. However, as with any data transfer on the
          internet, no method is completely secure, and we cannot guarantee
          absolute security.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Cookies and tracking
        </Typography>
        <Typography variant="body1" paragraph>
          Our website uses cookies to provide you with a better browsing
          experience. Cookies collect information about your browsing habits and
          preferences. You may disable cookies in your browser settings;
          however, certain features of our website may not function properly.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Data transfers to third countries
        </Typography>
        <Typography variant="body1" paragraph>
          We may transfer your data outside of Switzerland or the European Union
          in compliance with applicable data protection laws. In such cases, we
          ensure the recipients of the data provide appropriate safeguards for
          its protection.
        </Typography>

        {/* Final contact information */}
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          MyZurich
          <br />
          Zurich, Switzerland
          <br />
          Email: info@myzurich.com
          <br />
          Phone: +41 123 456 7890
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
