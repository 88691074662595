import "./styles/App.css";
import React, { Suspense, lazy } from "react";
import Navbar from "./components/layout/Navbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/masonry.css"; // This assumes that your masonry.css is inside a styles directory
import ScrollToTop from "./components/layout/ScrollToTop";
import CircularProgress from "@mui/material/CircularProgress"; // Add this import
import ManagerResetPassword from "./pages/management/ManagerResetPassword";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import TermsOfService from "./pages/public/TermsOfService";
import LegalDisclaimer from "./pages/public/LegalDisclaimer";
import AdminLogin from "../src/pages/public/AdminLogin"; // Import AdminLogin
import AutoScrollToTop from "./components/layout/AutoScrollToTop";
import BookingDetails from "./pages/public/BookingDetails"; // Import the new component

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(0, 0, 0, 0.6)",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
  typography: {
    fontFamily: "Monospace",
  },
  // typography: {
  //   fontFamily: 'Open Sans Condensed, Tahoma, Verdana, sans-serif',
  // },
});

// Lazy load the pages
const MainPage = lazy(() => import("./pages/public/MainPage"));
const RestaurantsPage = lazy(() => import("./pages/public/RestaurantsPage"));
const RestaurantDetails = lazy(
  () => import("./components/details/RestaurantDetails")
);
const EventsPage = lazy(() => import("./pages/public/EventsPage"));
const BarsPage = lazy(() => import("./pages/public/BarsPage"));
const ClubsPage = lazy(() => import("./pages/public/ClubsPage"));
const AdminDashboard = lazy(() => import("./pages/management/AdminDashboard"));
const ManagerDashboard = lazy(
  () => import("./pages/management/ManagerDashboard")
); // Import Manager Dashboard
const MeetTheChefPage = lazy(() => import("./pages/public/MeetTheChefPage")); // Import Meet the Chef Page
const PopupDetails = lazy(() => import("./components/details/PopupDetails"));
const Members = lazy(() => import("./pages/public/Members")); // Lazy load Members page
const BecomeMember = lazy(() => import("./pages/public/BecomeMember")); // Lazy load Be a Member page

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AutoScrollToTop /> {/* Automatically scroll to top on route changes */}
        <Navbar />
        <ScrollToTop /> {/* Add the ScrollToTop component */}
        <Suspense fallback={<null />}>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/restaurants" element={<RestaurantsPage />} />
            <Route path="/restaurant/:id" element={<RestaurantDetails />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/bars" element={<BarsPage />} />
            <Route path="/clubs" element={<ClubsPage />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/manager" element={<ManagerDashboard />} />
            <Route path="/meet-the-chef" element={<MeetTheChefPage />} />
            <Route path="/popup/:id" element={<PopupDetails />} />
            <Route path="/members" element={<Members />} />
            <Route path="/become-member" element={<BecomeMember />} />
            <Route path="/booking-details" element={<BookingDetails />} />
            <Route
              path="/reset-password/:token"
              element={<ManagerResetPassword />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
